import React from "react"
import SEO from "../components/SEO"
import Layout from "../components/Layout/Layout"
import { graphql, navigate } from "gatsby"
import { Button } from "../components/Input/Input"
import ModalImage from "react-modal-image"
import WorkingImage from "../assets/images/image_in_work.png"

const RecipeTemplate = ({ data }) => {
  const productData = data.allSitePage.edges[0].node.context

  const generateRecipeData = () => {
    if (productData.content.length > 0) {
      return productData.content.map((element, index) => {
        return (
          <div
            className="recipe-flex-box-item"
            key={index}
            id={"section_" + index}
          >
            <div className="recipe-flex-box-item-title">
              <p>{index + 1}</p>
            </div>
            <div className="recipe-flex-box-content">
              <div>
                {element.image === "" ? (
                  <ModalImage
                    hideDownload={true}
                    hideZoom={true}
                    small={WorkingImage}
                    large={WorkingImage}
                    alt="Bild ist noch in Bearbeitung"
                  />
                ) : (
                  <ModalImage
                    hideDownload={true}
                    hideZoom={true}
                    small={"/recipe/" + productData.url + "/" + element.image}
                    large={"/recipe/" + productData.url + "/" + element.image}
                    alt={element.image}
                  />
                )}
              </div>
              <div
                className="recipe-flex-box-content-text"
                dangerouslySetInnerHTML={{ __html: element.text }}
              />
            </div>
            <div className="recipe-flex-box-item-navigation">
              {index !== 0 ? (
                <Button
                  text="Zurück"
                  onClick={() => {
                    if (index === 1) {
                      navigate("#")
                    } else {
                      navigate("#section_" + (index - 1))
                    }
                  }}
                />
              ) : (
                ""
              )}
              {index !== productData.content.length - 1 ? (
                <Button
                  text="Weiter"
                  onClick={() => {
                    navigate("#section_" + (index + 1))
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        )
      })
    } else {
      return (
        <p style={{ textAlign: "center" }}>
          Leider sind keine Daten gefunden worden!
        </p>
      )
    }
  }

  return (
    <>
      <SEO title={productData.name} />

      <Layout url={"/" + productData.main}>
        <div className="recipe-template-wrapper">
          <h1>{productData.name}</h1>
          <div className="recipe-flex-box-wrapper">{generateRecipeData()}</div>
        </div>
      </Layout>
    </>
  )
}

export default RecipeTemplate

export const query = graphql`
  query($path: String) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          context {
            main
            name
            url
            content {
              image
              text
            }
          }
        }
      }
    }
  }
`
